.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }  
  
.card {
    box-shadow: 0 0.2em 0.25em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: 0.2em solid #1ba0f220;
    user-select: none;
    background-color: white;
}

.column {
    float: left;
    width: 33.3%;
    margin-bottom: 1em;
    padding: 0 0.5em;
    max-width: 300px;
  }

.twenty .column {
  width: 25%;
}

.title {
color: grey;
margin-top: -0.6em;
}

.row2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
}

.filterRow select {
  margin-right: 2em;
}

.container {
    padding: 0 0.8em;
  }
  
  .container h2 {
    font-size: 1.3em;
  }

.container::after, .row2::after {
    content: "";
    clear: both;
    display: table;
}

.genderBtns {
  margin-bottom: 1em;
}

.genderBtns button {
  margin-left: 1em;
  border: 0;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, transform 0.2s;
}

.genderBtns button:hover:nth-child(2) {
  background-color: #E6F5FD;
}

.genderBtns button:hover:nth-child(3) {
  background-color: rgba(255, 192, 203, 0.3);
}

.genderBtns button:nth-child(1).active {
  font-weight: bold;
}

.genderBtns button:nth-child(2).active {
  font-weight: bold;
  background-color: #E6F5FD;

}.genderBtns button:nth-child(3).active {
  font-weight: bold;
  background-color: rgba(255, 192, 203, 0.3);
}

@media screen and (max-width: 1000px) {
  .twenty .column {
    width: 25%;
  }
}


@media screen and (max-width: 700px) {
  .column {
    float: left;
    width: 100%;
    margin-bottom: 2em;
    padding: 0;
  }

  .column:last-of-type {
    margin-bottom: 0em;
  }

  .twenty .column {
    width: 47%;
    margin: 0.3em;
  }
}

@media screen and (max-width: 500px) {
  .twenty .column {
    width: 100%;
  }
  
}
