.page {
    min-width: 98.5vw;
}

.article.newsletters {
  max-width: 1600px;
}

.small-info {
  font-size: 0.8em;
  margin-top: 0;
  color: #007bff;
}

.small-info i {
  color: #007bff;
}

.div-readmore.addSection {
    margin-top: 0;
}

.article-status {
    margin-top: -0.75em;
    margin-left: -0.2em;
    border-radius: 0;
}

/* STATUS */
.status-published, .status-draft {
    padding: 0.5em 0.5em;
    border-radius: 0;
    margin: 0;
    user-select: none;
  }

  
  .status-published, .greenBg {
    color: green;
    background-color: #c8fec3;
  }
  
  .status-draft {
    color: orange;
    background-color: rgb(252, 242, 223)
  }
  
  .status-published i, .greenBg i {
    color: green;
    padding: 0;
  }
  
   .status-draft i {
    color: orange;
    padding: 0;
  }
  

  .max-600 {
    max-width: 600px;
  }

@media(max-width: 799px){
    .createArticleBtn {
        min-width: 100%;
    }
    
    .div-readmore.status.createArticleBtn {
        margin-left: 0;
    }
    
    .div-readmore.addSection {
        text-align: center;
    }
    
    }