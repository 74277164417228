.navbar-container {
    position:relative;
    top: 0;
    background: #1ba0f2;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    user-select: none;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2em 1em;
    color: white;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 1600px;
    margin: auto;
  }

  
  .navbar-brand {
    display: flex;
    align-items: center; /* Align logo and text vertically */
    gap: 0.5em; /* Spacing between logo and text */
    font-size: 1.5em; /* Adjust font size */
    font-weight: bold;
    color: white;
    margin: 0.25em 0em;
  }

  .navbar-logo {
    height: 2.5em; /* Matches the text height */
    width: auto; /* Maintains aspect ratio */
    object-fit: contain; /* Ensures proper scaling */
    border-radius: 50%;
    background-color: white;
    border: 0.05em solid #E6F5FD;
    padding: 0em;
  }

  .navbar-logo:hover, .navbar-brand:hover {
    cursor: pointer;
  }
  
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }

  .menu-toggle i {
    color: white;
  }

  
  .nav-links {
    display: flex;
    list-style: none;
    gap: 1.3em;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    font-weight: bold;
  }
  
  .nav-links.open {
    transform: translateX(0);
  }
  
  .nav-item {
    position: relative;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    padding: 0.5em 0;
  }
  
  .nav-link:hover {
    border-bottom: 2px solid white;
  }
  
  .submenu {
    display: none;
    position: absolute;
    background: white;
    color: #1ba0f2;
    list-style: none;
    top: 100%;
    left: 0;
    min-width: 150px;
    padding: 0.5em 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .nav-item:hover .submenu {
    display: block;
  }
  
  .submenu-link {
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    color: #1ba0f2;
  }
  
  .submenu-link:hover {
    background: #1ba0f2;
    color: white;
  }

  
  .logout-btn {
    background: none;
    border: 1px solid white;
    color: white;
    padding: 0.5em 1em;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .logout-btn:hover {
    background: white;
    color: #1ba0f2;
  }


  .sponsor-link {
    margin-left: 1em;
    background-color: rgba(62, 91, 255, 0.5);
    border-radius: 0.3em;
    padding: 0.5em 1em;
    transition: background-color 0.3s ease;
  }
  
  .sponsor-link i {
    color: white; /* Ensures the icon is visible */
  }
  
  .sponsor-link:hover {
    background-color: rgba(62, 91, 255, 0.7);
  }

  .intranet-styling {
    margin-left: 1em;
    background-color: rgba(62, 91, 255, 0.5);
    border-radius: 0.3em;
    padding: 0.5em 1em;
    transition: background-color 0.3s ease;
  }

  .intranet-styling i {
    color: white;
  }
  
  @media (max-width: 800px) {
    .menu-toggle {
      display: block;
      margin-right: 0.5em;
    }

    .navbar-container div{
        padding: 0.5em;
    }

    .navbar-brand {
        font-size: 1.2em;
    }
  
    .nav-links {
        display: none;
        position: absolute;
        top: 4rem; 
        background: #E6F5FD;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em;
        border-radius: 0.5em;
        gap: 0.75em;
        border-bottom: 0.2em solid#1ba0f2;
    }

    .navbar {
    padding: 0em;
    }

    .nav-links.open {
        display: flex;
        width: 100%;
    }

    .nav-item, .nav-link {
        margin: 0;
        width: 100%;
    }

    .nav-item.intranet-styling {
        margin: 0;
        padding: 0;
        background-color: inherit;
    }

    .nav-link {
        display: block; 
        border: none;
        padding: 0.4em 0.8em;
        color: #333;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        font-weight: bold;
        border-bottom: 0.1em solid #1ba0f2;
        max-width: 95%;
        margin: auto;
        background-color: white;
        transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
        /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
        text-align: left;
    }

    .nav-link:hover {
        border-bottom: 0.2em solid #1ba0f2;
      }

    .submenu {
        display: none; /* Submenus will not appear as dropdowns */
    }

    .sponsor-link {
        background-color: inherit;
        padding: 0;
    }

    .sponsor-link i, .intranet-styling i {
        color: #333; /* Ensures the icon is visible */
      }

      .nav-item .logout-btn {
        color: #1ba0f2;
        border: 1px solid #1ba0f2;
        display: flex; /* Flexbox for centering */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        margin: 0em auto; /* Add spacing and center the button */
  
    }
  }

  
  .intranet-styling:hover {
    background-color: rgba(62, 91, 255, 0.7);
  }
  
  .request-count.navbar {
    background-color: white;
    color: #1ba0f2;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    margin-left: 0.5em;
    display: inline-block;
    text-align: center;
  }

  
  
  