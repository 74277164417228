* {
  box-sizing: border-box;
  cursor: default;
}

/* Style the body */
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 1em;
  background-color: #E6F5FD;
  background: linear-gradient(0, #d6edf9, #E6F5FD); 
  color: #443c3e;
  margin: auto;
  overflow-x: hidden;
  /* border: 0.05em solid #1ba0f220; */


  margin: auto;
}

html {
  font-size: 1em;
  min-width: 19em;
}

p, i, u, b {
  line-height: 1.8em;
  font-family: Arial, Helvetica, sans-serif;
  color: #443c3e;
}  

a {
  font-family: Arial, Helvetica, sans-serif;
  color: #443c3e;
  cursor: pointer;
}

u {
  text-decoration:none;
  border-bottom: 0.15em solid #1ba0f2;
}

.max1600 {
  max-width: 1600px;
  margin: auto;
}


.flex-container-2 h2 {
  margin-top: 0.6em;
  margin-right: 0.35em;
  margin-bottom: 0em;
}


.flex-container-2 h3 {
  margin-top: 0.7em;
  margin-right: 0.35em;
  margin-bottom: 0em;
}

.white, .white i {
  color: white;
}


h4 {
  margin-top: 1.9em;
  margin-bottom: -0.6em;
}

h5 {
  margin: 0;
}

br {
  user-select: none;
}

input {
  text-indent: 0.3em;
}

button {
  padding: 5px 10px;
  font-size: 0.8em;
  color: #333;
  background-color: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 3px;
  gap: 1em;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.select {
  width: 100%;
  height: 2.8em;
  margin-top: 0.6em;
  font-size: 1em;
  padding: 0.3em;
  border-bottom: 0.2em solid #1ba0f220;

  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #1ba0f220;
  user-select: none;
  color: #443c3e;
}

.select {
  border-radius: 0.3em;
}

.select:focus {
  border: 0em;
  outline: none;
  border-bottom: 0.2em solid #1ba0f220;
}

input:focus {
  /* border: 0em; */
  outline: none;
}

input:hover {
  cursor: pointer;
}

textarea {
  width: 100%;
  margin-top: 0.6em;
  font-size: 1em;
  padding: 0.6em;
  resize: none;
  border-bottom: 0.2em solid #1ba0f220;

  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #1ba0f220;

  font-family: Arial, Helvetica, sans-serif;
}

textarea:hover {
  cursor: pointer;
}

fieldset {
  border: none;

}

label {
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}

select {
  cursor: pointer;
}

select:disabled {
  cursor: not-allowed;
}


.home-newsletter-header, .star {
 user-select: none;
}

.home-newsletter-header u:hover {
  border-bottom: 0.2em dotted #1ba0f2;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .home-newsletter-header {
     margin-left: 1em;
  }
}

.file-upload-parent {
  font-size: 0.8em;
}

.file-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-upload-button {
  display: inline-block;
  cursor: pointer;

  padding: 0.5em;
  border: 0.05em solid rgb(237, 237, 237);
  display: inline-block;
  border-radius: 0.15em;
}

.file-upload-button:hover {
  background-color: rgb(217, 216, 216);
}

.btn {
  padding: 1.3em;
  padding-left: 0;
}

.btn a {
  text-decoration: none;
  color: black;
  padding: 1.3em;
}

button {
  user-select: none;
}

.button:hover{
  cursor: pointer;
}

.button-design {
  border: none;
  display: inline-block;
  padding: 0.3em;
  text-align: center;
  width: 100%;

  background: #1ba0f2;
  color: #fff;
  outline: none;
  border-radius: 0.3em;
  cursor: pointer;
  box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2) ;
  transition: transform .2s;
  user-select: none;
  margin-bottom: 0.8em;
}

.button:hover, .button-design:hover {
  transform: scale(1.05);
}

.button-design.form:hover {
  transform: scale(1.02);
} 

.button {
  border: none;
  display: inline-block;
  padding: 0.3em;
  text-align: center;
  width: auto;

  background: #1ba0f2;
  color: #fff;
  outline: none;
  border-radius: 0.3em;
  cursor: pointer;
  box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.2) ;
  transition: transform .2s;
  user-select: none;
  margin-bottom: 0.8em;
}


.title {
  color: grey;
  margin-top: -0.6em;
}

.readmore {
  cursor:pointer;
  margin-top: 0px;
  margin-bottom: 0px;
}  

.readmore i {
  cursor: pointer;
}

.red, .red i {
  color: red;
}

.orange, .orange i {
  color: orange;
}

.green, .green i {
  color: green;
}

.allNewsLetters {
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.allNewsLetters i {
  margin-right: 0.3em;
}

.allNewsLetters:hover {
  cursor: pointer;
}



.row2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
}

.row {  
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: auto;
  max-width: 1600px;
}

.main {   
  flex: 73%;
  padding: 1.3em;
  background-color: inherit;
  overflow-y: auto;
  min-height: 400px;
}

.max-1000 {
  max-width: 1000px;
}

.side {
  flex: 27%;
  background-color: inherit;
  padding: 1.3em;
  position: sticky;
  bottom: 0rem;
  align-self: flex-end;
}

img {
  user-select: none;
}


.img, .mySlides {
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  border: 0.3em solid #1ba0f220;
  user-select: none;
}

.img-nozoom {
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  user-select: none;
}

.img-nozoom-border {
  width: 100%;
  height: 100%;
  user-select: none;
  border: 0.3em solid #1ba0f220;
}

.img:hover, .mySlides:hover {
  cursor: zoom-in;
}

.side-vision {
  text-align: center;
}

.side-vision p {
  margin: 0.7em 0;
}

.logo {
  width: 100%;
  display: flex; 
  align-items: center;
  justify-content: center;
  margin-bottom: 1.9em;
  margin-top: 0.9em;
}

.logo img {
  width: 90%;
}

.home-image {
  min-width: 100vw;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.3em solid #1ba0f220;
  position: relative;
}

.home-image img {
  min-width: 100vw;
  height: auto;

}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #1ba0f2, rgba(0, 0, 0, 0.5) 50%);
  z-index: 1; /* Ensures it's above the image, but below the text */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


.overlay-text {
  position: absolute; 
  top: 48%;
  left: 8.5%;
  transform: translate(-20%, -50%);
  font-size: 1.8vw; 
  color: white;
  z-index: 2;
  user-select: none;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.6); */
  padding: 0.8em;
  line-height: 1.35;
  border-radius: 0.2em;

  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

/* .overlay-text:hover {
  box-shadow: 0 0.15em 0.3em rgba(0, 0, 0, 1.1);
} */
  
  @media(max-width: 800px) {
    .overlay-text {
      font-size: 2.3vw; 
      /* top: 48%;
      left: 8.5%; */
      box-shadow: 0 0 0;
    }
  }

  /* ------------------------- */

.slideshow {
    width: 100%;
    height: 70vh;
    position: relative;
    overflow: hidden;
}

.slideshow-item {
    width: inherit;
    height: inherit;
    position: absolute;
    opacity: 0;
    animation: cycleImages 31s infinite;
    user-select: none;
}

.slideshow-item:nth-child(1), .slideshow-item:nth-child(1) img {
    animation-delay: 0s;
}  

.slideshow-item:nth-child(2), .slideshow-item:nth-child(2) img {
    animation-delay: 10s;
}  

.slideshow-item:nth-child(3), .slideshow-item:nth-child(3) img {
    animation-delay: 20s;
}



.slideshow-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: zoom 31s infinite;
}

.slideshow-item-text {
    max-width: 40%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.53);
    color: #fff;
    margin: 0;
    padding: 0.9em;
    padding-left: 1.6em;
    border-radius: 0 0.3em 0.3em 0;
}

.slideshow-item-text h5 {
    font-size: 1.6em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: 1em;
    color: #1ba0f2;
}

.slideshow-item-text p {
    font-size: 0.9em;
    letter-spacing: 0.1em;
    font-weight: 300;
    color: white;
}

@keyframes cycleImages {
    5% {
        opacity: 1;
    }

    50% {
      opacity: 1;
  }      

    70% {
        opacity: 0;
    }
}

@keyframes zoom {
    100% {
        transform: scale(1.3);
    }
}

@media screen and (max-width: 1000px) {
    .slideshow-item-text {
        max-width: 70%;
        padding: 0.9em;
    }

    .slideshow-item-text h5 {
        font-size: 1.6em;
    }
}

@media screen and (max-width: 767px) {
    .slideshow-item-text {
        max-width: 100%;
        top: initial;
        bottom: 0;
        transform: initial;
        padding: 0.9em;
        background-color: rgba(0, 0, 0, 0.445);
        text-align: center;
    }

    .slideshow-item-text h5 {
        font-size: 1.4em;
        padding: 0.6em;
        margin: 0;
    }      
    
    .slideshow-item-text p {
        font-size: 0.9em;
    }
}

.column {
  float: left;
  width: 33.3%;
  margin-bottom: 1em;
  padding: 0 0.5em;
}

.column-2 {
  float: left;
  width: 33.3%;
  margin-bottom: 1em;
  padding: 0 1em;
}



@media screen and (max-width: 800px) {
  .row {   
    flex-direction: column;
  }

  .main {
    margin-top: 1.3em;
    padding: 0.2em;
    flex: 1;
    order: 1;
  }

  .side {
    flex: 1;
    order: 2;
    padding: 1.3em;
    position: relative; /* Explicitly set position for smaller screens */
  }
}  

@media screen and (max-width: 799px) {
  .column {
    width: 100%;
    display: block;
  }
  
  .main {
    flex: 55%;
  }

  .side {
    flex: 45%;
  }

}

.article, .article-readmore {
  background-color: white;
  padding: 1.3em;
  margin-left: -1.3em;
  border-radius: 0.3em;
  cursor: default;
  box-shadow: var(--timelineCardBoxShadow, 0 0.1em 0.2em 0 rgba(0, 0, 0, .12), 0 0.1em 0.2em 0 rgba(0, 0, 0, .24));
}  

@media screen and (min-width: 800px) {
  .article-readmore:hover .div-readmore, .article-card:hover .div-readmore {
    transform: scale(1.05);
  }
}



.article-click {
  background-color: white;
  padding: 1.3em;
  margin-left: -1.3em;
  border-radius: 0.3em;
  cursor: pointer;
  box-shadow: var(--timelineCardBoxShadow, 0 0.1em 0.2em 0 rgba(0, 0, 0, .12), 0 0.1em 0.2em 0 rgba(0, 0, 0, .24));
}

.article-click a {
  line-height: 1.6em;
}  

.article-click.goToNews {
  text-align: center;
  transition: transform .2s;
  user-select: none;
}  

.article-click.goToNews:hover {
  transform: scale(1.015);
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}  

.card {
  box-shadow: 0 0.2em 0.25em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 0.2em solid #1ba0f220;
  user-select: none;
}

.program-card {
  flex: 33.33%;
  padding: 0.3em;
  margin: 1em 0em;
  background-color: white;
  margin-bottom: 0;
}

.program-card:hover {
  cursor: pointer;
}

.program-card span:hover {
  cursor: pointer;
}

.program-card h3 {
  margin-left: 0.5em;
  cursor: pointer;
}

.program-card i {
  cursor: pointer;
}

.program-img {
  width: 100%; /* Adjust if necessary */
  height: auto; /* Keep the aspect ratio */
  display: block; /* Remove extra space below the image */
  cursor: pointer;
}

.programsTxt {
  margin: 1em;
  margin-top: 1.3em;
}

.programsTxt .flex-image-text img {
  max-width: 35%;
  min-width: 35%;
  height: auto; 
}

.feature-box {
  border-radius: 0.3em;
  padding: 5px 10px;
  display: inline-block;
  color: black;
  margin: 0.5em 0.3em;
  font-size: 1.1em;
  user-select: none;
  
}

.feature-box.fullWidth {
  width: 100%;
  margin: 0;
  margin-top: 0.5em;
}

.feature-box.grey {
  background-color: rgba(211, 211, 211, 0.4);
}

.feature-box.green {
  background-color: rgba(144, 238, 144, 0.4);
  background-color: rgba(144, 238, 144, 0.8);
  border-bottom: 0.15em solid green;
  color: black;
}

.feature-box.blue {
  background-color: #E6F5FD;
  border-bottom: 0.15em solid #1ba0f2;
  cursor: pointer;
}

.feature-box.blue i {
  cursor: pointer;
}

.student-description {
  width: 100%;
  height: auto;
  min-height: 100px;
  resize: vertical;
  border: 0.2em solid lightblue;
  margin-bottom: 1em;
}

@media screen and (max-width: 800px) {
  .program-card {
    flex: 0 0 100%;
  }

  .program-card h3 {
    padding-top: 0.8em;
  }

  .column-2 {
    width: 50%;
  }

  .programsTxt .flex-image-text img {
    display: none;
  }
}

@media screen and (max-width: 500px) {

  .column-2 {
    width: 100%;
  }

}

.program-card .flex-container-2 {
  margin-bottom: 1em;
}

.program-card .flex-container-2 span {
  margin-top: 0.8em;
  margin-left: 0.3em;
}


/* Fake image, just for this example */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 1.3em;
}

.userData-info {
  font-size: 0.85em;
  margin: auto;
}

.userData-info a:hover {
  background-color: inherit;
  cursor: default;
}

.userData-info u {
  color: white;
  border-bottom: 0.1em solid white;
}

.article.newsletters {
  margin:0;
}


.advisory-board-container .column {
  width: 25%;
  font-size: 0.85em;
}


/* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .row {   
    flex-direction: column;
  }

  .main {
    margin-top: 1.3em;
    padding: 0.2em;
  }

  .article-click, .article {
    margin: 0.6em;
    margin-top: 0;
    padding: 0.9em;
  }

  .programsTxt {
    margin: 1em;
  }


  .div-back {
    margin-left: 0.6em;
  }

  .article.newsletters {
    margin: 0;
  }

  .advisory-board-container .column {
    width: 45%;
    font-size: 0.8em;
    margin: 0.5em;
  }


}

.container {
  padding: 0 0.8em;
}

.container h2 {
  font-size: 1.3em;
}

textarea {
  background-color: white;
  border: 0;
}

textarea:focus{
  outline: none;
}

/* -------------- INPUT AND PLACEHOLDER DEFAULT ----------------- */

input, select {
  padding: 0.5em;
  padding-left: 0;
  border: 0.05em solid rgb(237, 237, 237);
  display: inline-block;
  border-radius: 0.15em;
}


input::placeholder, select::placeholder {
  color: #443c3e;
  font-size: 0.95em;
}

select:focus {
  outline: none;
}

input + input, input + select, select + input, select + select {
  border-left: none;
  margin-top: 0.5em;
}

input.amountNumber {
  width: 2em;
  text-align: center;
}


/* -------------- INPUT END ----------------- */

.h2 {
  font-size: 1.3em;
}

.h3 {
  font-size: 1em;
}

.container::after, .row2::after {
  content: "";
  clear: both;
  display: table;
}

a {
  text-decoration:none;
}

.fa-bars {
color: white;
}

.fa-remove {
color: white;
}

.fab:hover {
  cursor: pointer;
}

.flex-image-text {
display: flex;
align-items: center;
justify-content: flex-start; 
}

.flex-image-text > div > p {
  margin-top: 0;
  /* border: 1px solid blue; */
  padding: 0.8em 0em;
  margin-left: 0.05em;
}



@media screen and (min-width: 800px) {
  .flex-image-text div {
    padding-right: 0.9em;
  }

  .programsTxt .flex-image-text div {
    padding-right: 0em;
  }
}


.flex-image-text h2 {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  }

.flex-image-text h3 {
  margin-bottom: 0;
  margin-top: 0.2em;
}

.flex-image-text img {
max-width: 50%;
min-width: 50%;
height: auto; 
}

.flex-container-2.subheader h3 {
  margin-top: 0em;
}

@media all and (max-width: 799px) {
.flex-image-text {
  flex-flow: column wrap;
  padding: 0;
}

.flex-image-text h3 {
  margin-bottom: 0;
  margin-top: 1em;
}

.flex-image-text img {
  max-height:none;
  max-width: 100%;
  margin: 0;
  margin-top: 0.5em;
}

.flex-container-2.subheader {
  margin-top: 0.6em;
}

.flex-image-text > div {
  padding-right: 0em;
}
}

.flex-image-text.donate img {
  max-width: 30%;
  min-width: 30%;
}

@media all and (max-width: 799px) {
  .flex-image-text.donate img {
    max-width: 60%;
    min-width: 60%;
  }
  }

.email {
  padding: 0em 0em;
  margin: 0.125em 0;
}


.coming {
  user-select: none;
  background-color: #E6F5FD;
  color: #1ba0f2;
  padding: 0.3em 0.3em;
  border-radius: 0.3em;
  margin: 0;
  font-weight: bold;
}

.message {
  font-size: 0.8em;
}

.center {
  display: flex;
  justify-content: center;
}

.left {
  text-align: left;
}


.vision-statement {
line-height: 200%; 
text-align: center;
}

.vision-statement span {
color: #53494c;
font-weight: bolder;
}

.slideshow-content {
margin-left:auto;
margin-right:auto;
max-width:800px;
}

.slideshow-display-container {
position:relative;
}

.slideshow-button-left {
position:absolute;
top:50%;
left: 0.2em;
transform:translate(0%,-50%);
-ms-transform:translate(-0%,-50%);
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}

.slideshow-button-right{
position:absolute;
top:50%;
right: 0.2em;
transform:translate(0%,-50%);
-ms-transform:translate(0%,-50%);
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
}

.slideshow-btn-color {
color:#fff!important;
background-color:#00000042!important;
}

.slideshow-btn-color:hover {
color:#1ba0f2!important;
transition: color 0.2s ease;
}

.slideshow-button {
border:none;
display:inline-block;
padding:0.5em 1em;
padding:0.5vw 1vw;
font-size: 1.5em;
vertical-align:middle;
overflow:hidden;
text-decoration:none;
color:inherit;
background-color:inherit;
text-align:center;
cursor:pointer;
white-space:nowrap;
touch-action: manipulation;
}

.dot {
cursor: pointer;
height: 0.9em;
width: 0.9em;
margin: 0.5em 0.2em;
background-color: #bbb;
border-radius: 50%;
display: inline-block;
transition: background-color .6s ease
}

.activeDot, .dot:hover {
background-color: #717171;
}


/* ------------------------- */


.icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fa.fa-bars, .fa.fa-remove {
margin-top: 0.15em;
}


.mobile-open {
  display: block !important;
}

@media(max-width: 799px) {
.navigation {
  display: none;
}

.flex-container-2 h3 {
  margin-top: 0;
}


}

/* ------------------- */

.pointer:hover {
cursor: pointer;
}

.chess-peak {
text-align: center;
cursor: default;
}

.chess-peak img {
  border: 0.3em solid #1ba0f220;
}

.newsletter-wrapping {
padding: 0.6em;
margin-right: 0.6em;
margin-top: 0.3em;
margin-bottom: 0.6em;
border-radius: 1.3em;
background-color: #1ba0f227;
border: 0.1em solid rgba(128, 128, 128, 0.352);
display: inline-block;
transition: transform .2s;
cursor: pointer;
user-select: none;
}

.newsletter-wrapping a {
color: #443c3e;
}

.newsletter-wrapping:hover {
transform: scale(1.05);
}

.price {
padding-left: 0.3em;
padding-right: 0.3em;
margin-right: 0.6em;
margin-top: 0.9em;
margin-bottom: 0.3em;
border-radius: 0.6em;
background-color: #1ba0f213;
border: 0.05em solid rgba(128, 128, 128, 0.352);
display: inline-block;
transition: transform .2s;
font-size: 0.9em;
user-select: none;
}

.div-readmore {
  padding-top: 0.3em;
  padding-left: 0.6em;
  padding-bottom: 0.3em;
  padding-right: 0.6em;

  margin: 0;
  margin-top: 1em;

  border-radius: 0.4em;
  display: inline-block;
  transition: transform .2s;
  user-select: none;
  cursor: pointer;

  border: 0;
  border-bottom: 0.18em solid #1ba0f220;
  border-bottom-style: groove;
  background-color: rgba(211, 211, 211, 0.150);
  user-select: none;
  color: #443c3e;
}

.div-readmore.status {
  margin-left: 0.5em;
}

.div-readmore.status:hover {
  cursor: pointer;
}

.div-readmore a {
color: #443c3e;
}

.div-readmore:hover {
transform: scale(1.05);
}

.div-readmore i:hover {
  cursor: pointer;
}


.div-readmore.form:hover {
transform: scale(1.02);
}

.div-readmore.status.edit {
  margin-top: 0;
}

.div-back {
padding: 0.6em;
padding-top: 0.3em;
padding-bottom: 0.3em;
margin-right: 0.6em;
margin-top: 0;
margin-bottom: 0.6em;
border-radius: 0.4em;

background-color: rgba(211, 211, 211, 0.125);
display: inline-block;
transition: transform .2s;
user-select: none;
border: 0.2em solid #1ba0f220;
background-color: #e6f5fd;
cursor: pointer;
}

.div-back i {
  cursor: pointer;
}

.div-back a {
color: #443c3e;
}

.div-back:hover {
transform: scale(1.05);
}

@media(max-width: 799px){
.div-readmore{
  width: 100%;
}

.div-readmore.status {
  max-width: 47%;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
  text-align: center;
}

.div-readmore.status.edit {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.date.dateInput {
  min-width: 100%;
}

.file-upload-button.header {
  min-width: 100%;
}

.hideOnPhone {
  display: none;
}

}

.timeline__year {
user-select: none;
}


/* ------------------- */

#myBtn {
  display: none;
  position: fixed;
  bottom: 0.6em;
  right: 1.3em;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #1ba0f2a7;
  color: white;
  cursor: pointer;
  padding: 0.9em;
  padding-bottom: 0.8em;
  border-radius: 0.6em;
  font-size: 1.1em;
}

#myBtn:hover {
  background-color: #1565C0;
  /* Add a dark-grey background on hover */
}


/* ----- FOOTER --------------- */

.container-footer{
max-width: 73em;
margin: auto;
line-height: 1.3;
}

.container-row{
display: flex;
flex-wrap: wrap;
}

.footer-col ul {
list-style: none;
margin: 0;
padding: 0;
box-sizing: border-box;
}

.footer{
background-color: rgba(0, 0, 0, 0.8);
padding-top: 1.2em;
user-select: none;
}

.footer-col {
 width: 25%;
 margin: 0 auto;
 padding: 0em;
 text-align: center;
}

.footer-col h4{
  font-size: 1em;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 2.2em;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before{
  content: '';
  position: absolute;
  left:50%;
  bottom: -0.8em;
  background-color: #1ba0f2;
  height: 0.1em;
  box-sizing: border-box;
  width: 3.1em;
  transform: translateX(-50%); 
}

.footer-col ul li:not(:last-child){
  margin-bottom: 0.6em;
}

.footer-col ul li a{
  font-size: 0.9em;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
  margin-bottom: 1em;
}


.footer-col ul li p {
  font-size: 0.9em;
  color: #bbbbbb;
  margin-top: 0.3em;
}

.footer-col ul li .developed {
  line-height: 0;
  font-size: 0.6em;
  margin-top: 2em;
  margin-bottom: 0;
}

.footer-col ul li .adminLoginLink {
  margin-top: 0em;
  margin-bottom: 0.7em;
  padding: 0;
  font-size: 0.8em;
}

.footer-col ul li a:hover{
  color: #1ba0f2;
  padding-left: 0.5em;
  cursor: pointer;
}

.footer-col .social-links a{
display: inline-block;
height: 2.5em;
width: 2.5em;
background-color: #bbbbbb;
margin:0 0.3em 0.6em 0.3em;
text-align: center;
line-height: 2.5em;
border-radius: 50%;
color: #ffffff;
transition: all 0.5s ease;
}

.footer-col .social-links a:hover{
background-color: #1ba0f2;
}

.footer-end {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin-top: 1em;
  padding: 1em 0.3em;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.1);
}

.footer-item {
  width: 33%;
  font-size: 0.7em;
  text-align: center;
}

.footer-item.center {
  white-space: pre-line;
}

.footer-item.center .separator {
  display: inline-block; /* Show the separator */
  margin: 0 0.5em; /* Add some space around the separator */
}

.item-block {
  display: block; 
}


@media(max-width: 767px){
.footer-col{
  width: 50%;
  margin: auto 1em;
  text-align: left;
}

.footer-col h4::before{
  left:1.5em;
  transform: translateX(-50%); 
  }

.footer-item {
  width: 100%;
  margin: 0.5em auto;
}
}

@media(max-width: 574px){
.footer-col{
  width: 100%;
}
}


/* ----- TEXT UNDER IMAGE -------*/

.item, .item-partners {
min-width: 12em;
flex: 20%;
text-align:center;
background-color: transparent;
border: 0.1em solid transparent;
box-shadow: 0 0.3em 0.5em 0 rgba(0, 0, 0, 0.2);
margin-right: 0.3em;
margin-left: 0.3em;
margin-bottom: 0.1em;
}

.item img {
max-width: 12em;
max-height: 6em;
padding: 0em;
margin-top: 1em;
margin-bottom: 1em;
}

.item p {
padding: 0.5em;
padding-top: 0;
padding-bottom: 0;
}

.item .content {
  padding: 0.5em;
}

#index-gallery{
margin-top: 1.6em;
width:100%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: flex-start;
min-height: 26em;
}


/* -------------------- */

.img-nozoom.paypal {
margin-left: 1.3em;
max-width: 30%;
display: -webkit-box; 
margin-left: auto;
}

.button-design.paypal {
padding: 0.5em; 
max-width: 19em;
border: 0.1em solid rgba(128, 128, 128, 0.352);
margin-bottom: 0;
margin-top: 1em;
}

@media(max-width: 800px){
.img-nozoom.paypal {
  margin-top: 1.3em;
  max-width: 40%;
  margin-left: 0;
}
}

@media(max-width: 800px){
.img-nozoom.paypal {
  max-width: 100%;
}

.button-design.paypal {
  max-width: 100%;
  margin-top: 1em;
}
}

#vipps {
  background-color: #FF5B26;
  border: 0.1em solid rgba(128, 128, 128, 0.352);
}

#vippsMobile {
display: none;
background-color: #FF5B26;
border: 0.1em solid rgba(128, 128, 128, 0.352);
}

#vippsPc {
background-color: #FF5B26;
border: 0.1em solid rgba(128, 128, 128, 0.352);
}

#donorbox {
  background-color: #056BBE;
  border: 0.1em solid #1BA0F2;
}

#stripe {
  background-color: #635bff;
}


@media all and (max-width: 500px) {
#vippsMobile {
  display: block;
}

#vippsPc {
  display: none;
}

}


/* ----------------- */


/* Safari syntax */
:-webkit-full-screen {
background:content-box;
padding: 1.9em;
content: "Not supported";
}

/* IE11 */
:-ms-fullscreen {
background-color: white;
padding: 1.9em;
}

/* Standard syntax */
:fullscreen {
background-color: white;
border-radius: 0.3em;
padding: 1.9em;
}



/* -------------- FLEX -------------- */

.flex-header {
display: flex;
flex-wrap: wrap;
}

.flex-item {
flex-grow: 1;
text-align: center;
margin: 1.3em;
width: 25%;
}

/* ------------------------ */

.date {
background-color: rgba(211, 211, 211, 0.2005);
padding: 0.6em;
border-radius: 0.4em;
user-select: none;
display: inline-block;
margin: 0.1em 0;
}

.date i {
margin-right: 0.3em;
}

width {
width: 100%;
}

.iframe-div {
  position: relative; 
  margin-top: 1.3em;
  width: 100%;
  aspect-ratio: 16 / 9; /* Sets the aspect ratio */
  overflow: hidden;
}

iframe {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
width: 100%;
height: 100%;
}


.newsletter-signup {
margin-top: 3.1em;
text-align: center;
}

.newsletter-signup input {
margin: 0;
border: 0.2em solid #1ba0f220;
border-right: 0;
background-color: transparent;
}

p.newsletter {
margin-top: 0.5em;
font-size: 0.9em;
}

.eighty-twenty {
display: flex;
flex-wrap: nowrap;
}

.eighty-twenty input {
flex: 80%;
}

.eighty-twenty input:hover {
transition: transform .2s;
border: 0.2em solid #1ba0f25b;
}

.eighty-twenty button {
flex: 20%;
background-color: transparent;
border: 0.2em solid #1ba0f220;
font-size: 1.1em;
color: grey
}

.eighty-twenty button:hover {
cursor: pointer;
color: black;
transition: transform .2s;
border: 0.2em solid #1ba0f25b;
}

.eighty-twenty input:hover {
transform: scale(1.02);
}  

.eighty-twenty button:hover {
transform: scale(1.02);
}  

draft {
color: red;
font-size: 1.1em;
padding: 0.3em;
background-color: rgba(240, 128, 128, 0.334);
border-radius: 0.3em;
user-select: none;
}


.flex-container-2 {
display: flex;
flex-wrap: wrap;
align-items: center;
}

.flex-container-2 > .flex-container-2 {
  padding-right: 0;
  margin-top: 0;
}

.flex-container-2 input {
    flex: 1; 
}

@media (max-width: 800px) { 
  .flex-container-2.subheader h3 {
    margin-top: 0.6em;
    margin-bottom: 0em;
    margin-right: 0.2em;
  }
}

.flex-container-2 > .openhrs > .flex-container-2 > .when {
  padding-right: 0.4em;
}

.flex-container-2 > .flex-container-2 div {
  padding-left: 0.4em;
  padding-right: 0.4em;
  margin: 0 0.15em;
}


.when, .coach, .amount {
margin: 0.4em 0.25em 0.25em 0;
color: grey;
padding: 0.45em;
background-color: #1ba0f201;
border: 0.1em solid #1ba0f21b;
border-radius: 0.3em;
user-select: none;
font-size: 0.85em;
text-align: left;
justify-content: center;
}

.coach i, .when i, .amount i 
/* , .openhrs i  */
{
margin-right: 0.3em;
}

.mylink-coach {
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}

.coach:hover, .coach i:hover {
  cursor: pointer;
}


.openhrs {
margin: 0.3em 0.3em 0.6em 0;
color: rgba(51, 51, 51, 0.785);
user-select: none;
}

.counter {
  padding: 0.2em;
  font-size: 0.8em;
}


/* -------------------------- */

.partners {
margin-left: 0.3em; 
text-align: center;
}

.fa-solid.fa-arrow-up-right-from-square {
color: rgba(0, 0, 0, 0.25);
}

/* --------- OLD ADMIN -------------------- */

 .admin {
  text-align: center;
  padding: 1em 5em;
}

/*

.admin input {
  margin: 0;
  padding: 0;
  background-color: #1ba0f220;
  border-bottom: 0;
  text-align: center;
}

.admin select {
  width: auto;
}

.admin textarea {
  text-align: left;
  height: 3em;
  margin: 0;
}

.adminTable th, td {
  border: 1px solid black;
}

.adminTable th {
  width: 10%;
  padding: 0.2em;
}

.adminTable .description {
  width: 65%;
  padding-left: 0.5em;
}

.adminTable {
  text-align: center;
  padding: 0;
  width: 100%;
  margin: auto;
  border-collapse: collapse;
}

.adminLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */


/* ----- MESSAGE ------ */

/* .message {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  z-index: 100;
}

.message.show {
  display: block;
}

.message.success {
  color: green;
  background-color: rgba(0, 128, 0, 0.1);
}

.message.error {
  color: red;
  background-color: rgba(255, 0, 0, 0.1);
} */


/* ------------------------------------- */

.fullWidth {
  width: 100%;
}


/* -------- NEWSLETTER FORM ---------- */


.newsletterForm input, .newsletterForm button {
  max-width: 16em;

  width: 100%;
  height: 2em;
  margin-top: 0.6em;
  font-size: 0.95em;
  padding: 0.3em;
  border-bottom: 0.2em solid #1ba0f220;

  border-bottom-style: groove;
  background-color: #1ba0f220;
  user-select: none;
  color: #443c3e;

  border-radius: 0.2em;
}

.newsletterForm button {
  margin-top: 0.6em;
  max-width: 8em;
  font-weight: normal;
  background-color: rgba(211, 211, 211, 0.150);
}

.newsletterForm input::placeholder {
  color: #443c3e;
  font-size: 0.95em;
}

@media screen and (max-width: 764px) {
  .newsletterForm input, .newsletterForm button {
    max-width: 100%;
  }
} 



/* ------------ PARTNERS --------------- */

.partners-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.partners-section h2 {
  margin-bottom: 0;
  margin-top: 1.2em;
  user-select: none;
}

.partners-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center; 
}

.partner-item {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-item img {
  width: 12em;  
  height: auto;
  margin-bottom: 10px;
  cursor: pointer;
}

.partner-item h3 {
  margin-bottom: 0;
}

.partner-item p {
  margin-top: 0;
}

.rolling-loading-animation {
  display: inline-block;
  width: 15em; /* Match the specified width */
  height: 15em; /* Match the specified height */
  border: 0.8em solid lightblue; /* Lighter border color except for the top */
  border-radius: 50%; /* Fully rounded to create a circle */
  border-top-color: #1ba0f2; /* Distinct color for the top border to see the spinning effect */
  animation: spin 1s ease-in-out infinite;
  line-height: 2em; /* Center align the line height if needed */
}

.rolling-loading-animation.small {
  width: 1em; 
  height: 1em;

  border: 0.2em solid lightgreen; 
  border-top-color: green;
}

.rolling-loading-animation.small.blue {
  border: 0.2em solid lightblue;
  border-top-color: #1ba0f2; 
}

.rolling-wrapper {
  border: 1px solid red;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loading-container {
  position: relative;
  width: 100%;
  height: 1.8em;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 0.3em;
  overflow: hidden;
}

.loading-animation {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #1ba0f2;
  animation: loadingAnim 2s infinite;
}

@keyframes loadingAnim {
  0% {
      left: -100%;
  }
  50% {
      left: 0%;
  }
  100% {
      left: 100%;
  }
}

.loading-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 1.8em;
  color: #333;
}

iframe {
  position: static;
  height: auto;
  display: block;
  width: 100%;
  min-height: 500px;
}

.move-buttons {
  gap: 0.5rem;
  margin-top: 1rem;
}

.move-button {
  background-color: rgba(211, 211, 211, 0.150);
  border-bottom: 0.18em solid #1ba0f220;
  color: #333;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  align-items: center;
  gap: 0.2em;
}

.move-button:hover {
  opacity: 0.8;
}

.img-homepage {
  width: 100%;
  height: 100%;
  object-fit:contain;
  border: 0.3em solid #1ba0f220;
  object-position: right center;
}

/* Hide mobile image and show desktop image by default */
.img-homepage.coverMobile {
  display: none;
}

.img-homepage.cover {
  display: block;
}

/* For mobile view */
@media (max-width: 768px) {
  .img-homepage.cover {
    display: none;
  }

  .img-homepage.coverMobile {
    display: block;
  }
}


