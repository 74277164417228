.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  padding: 2rem;
  border-radius: 8px;
}

.login-container-2 {
  padding: 2em;
  border-radius: 0.5em;
  min-width: 30em;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container label {
  margin-bottom: 0.5rem;
}

.login-container input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.login-container-button {
  background-color: #007bff;
  color: white;
  padding: 0.6rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.login-container-button:hover {
  background-color: #0056b3;
}

.login-container .error, .login-container .errorCode {
  margin-top: 1em;
  color: #ff0000;
  text-align: center;
  font-size: 0.9em;
}

.login-container .errorCode {
  font-size: 0.75em;
}

.backFromAdmin {
  font-size: 0.8em;
  text-decoration: underline;
}

.loginoptions {
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  box-shadow: none;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.loginoptions .option {
  flex: 1;
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
  padding: 0.2em;
  margin: 0 0.4em;
  border-radius: 0.3em;
  user-select: none;

  border-radius: 0.4em;
  display: inline-block;
  transition: transform .2s;
  user-select: none;
  cursor: pointer;

  border: 0.1em solid #1ba0f220;
  border-bottom: 0.15em solid #1ba0f2;
  border-bottom-style: groove;
  background-color: #f9f9f9;
  user-select: none;
  color: #443c3e;
}

.loginoptions .option:hover p, .loginoptions .option i:hover {
  cursor: pointer;
}

.loginoptions .option:hover p, .loginoptions .option i:hover {
  transition: transform 0.2s ease;
  transform: scale(1.05);
}

.writePhoneNumber, .validPhoneNumber {
  font-size: 0.95em;
  color: orange;
  margin-top: 0.5em;
}

.validPhoneNumber {
  color: green;
}

button:disabled {
  opacity: 0.7;
}

@media(max-width: 700px) {
  .login-container {
    padding: 1rem;
    height: auto;
    min-height: auto;
    width: 100vw;
  }

  .login-container-2 {
    padding: 1em;
    border-radius: 0.5em;
    width: 100%;
    min-width: auto;
    min-height: auto;
  }

  .loginoptions {
    flex-direction: column;
  }

  .loginoptions .option {
    margin: 0.5em 0;
  }
}
